import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/8dd44871de1cffe/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/8dd44871de1cffe/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/tmp/8dd44871de1cffe/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/tmp/8dd44871de1cffe/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/tmp/8dd44871de1cffe/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Open_Sans\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"openSans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/8dd44871de1cffe/src/app/[locale]/loading.tsx");
;
import(/* webpackMode: "eager" */ "/tmp/8dd44871de1cffe/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/8dd44871de1cffe/src/components/CookiebotScript.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/8dd44871de1cffe/src/components/LocaleSwitcherSelect.tsx");
